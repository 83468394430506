/* import bg from "500px (5).jpeg" */

.App {
  text-align: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.carousel-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.slider {
  display: flex;
  transition: all 0.5s ease; /* Smooth sliding transition */
}

.slide {
  min-width: 100%;
  flex: 0 0 auto;
}
/* src/Carousel.css */
.slick-slide img {
  display: block;
  margin: auto;
  max-width: 100%;
}

.slick-dots li button:before {
  color: #000;
}
.iframe{
  margin-top: 5.6rem;
 width: 100%; 
}
.video-par{
  width: 100%;
   display: flex;
  justify-content: center;
  align-items: center;
}
.video{
 border-width: 0.5rem;
 border-color: #000;
 border-radius: 1rem;
 border-style: solid;
 align-items: center !important;
 height: 60vh;
 width: 55vw;
 margin-bottom: 1rem;
 
}

.iframeparent{

border-bottom: 0.1rem solid  #000;
border-radius: 0.1rem;
width: 100% !important;
}
.slick-prev:before,
.slick-next:before {
  color: #000;
}
.trend{
  text-align: center;
  font-weight: bold;
}

p {
  text-align: center;
  font-size: 1.2em;
  margin-top: 10px;
}

.prev-btn,
.next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #00FFFF; /* Background color for navigation buttons */
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.prev-btn {
  left: 10px;
}

.next-btn {
  right: 10px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.bg{
  @media (max-height:320px) {
  background-color:red
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.sign{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
 
 opacity: 0.9;

}
.bg{
  background-size: cover;
   height: 100%;
   width: 100%;
   opacity:0.9;
}
.tb{
  border-radius: 1rem;
  
  border-color:dodgerblue;
  padding: 0.5rem;
  border-width: 0.2rem;
  width: 20rem;

}

.slider-item {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.heroimg {
  width: 100%;
  height: 1200px !important;
  object-fit: fill !important;
 
}

/* Media query for larger screens */




.label{
  font-style: oblique;
  font-weight: 500;
  text-emphasis-color: blue;
}
.addp{
  font-weight: 500;
  text-emphasis-color: blue;
}

.divv{
  align-items: center !important;
}
.iframe{
  margin-top:0.1rem;
  width: 100%;
}
.iframeparent{

border-bottom: 0.1rem solid  #000;
border-radius: 0.1rem;
}
.hero-mid{
  width: 100%;
}

.iframeparent{

border-bottom: 0.1rem solid  #000;
border-radius: 0.1rem;
}
.sign-button{ 

 

border-radius: 0.7rem;
border-color:magenta;
border-width: 0.2rem;
}
.card{
  border-radius: 1rem;
  border-width: 0.2rem;
  border-color: purple;
}
.formE{

  display: grid;
 justify-content: center;

/* align-items: center;
  height: 100v; */ 
grid-row: 4;
grid-column: 1;
row-gap: 2rem;

}

@media (max-width: 768px) {
  .heroimg {
    max-height: 500px; /* Adjust this value as needed */
  }
  .pts{
    font-size: 1.1rem !important;
    font-weight: 500 !important;
  }
   ul { list-style-type: "✨" !important;
    padding-left:0rem ;
  }

}
@media (min-width:1024px) {
   .pts{
          margin-bottom: 0.5rem !important;
          font-size: 1.5rem !important;
          line-height: 1.5 !important;
          color: #1e2b46 !important;
              font-weight: 500 !important;

         
          position: relative !important;
      }
       ul { list-style-type: "✨" !important; }
}


.cnt{
  font-weight: 700 !important;
  font-size: 2.3rem !important;
  margin-top: 2rem !important;
}
.cnt1{
  font-weight: 800 !important;
  font-size: 2.3rem;
}
.products{
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
}
.login{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
 opacity: 0.9;
 
}
.ip{

visibility: hidden;

}
.nav{
  color:black!important;
  font-size:17px !important;
  font-family: 'Etna', sans-serif !important;
}

.imgForm{
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;

}
.parentmore{
  display: flex;
  justify-content: center;
}
.view{
 text-align: center !important; 
 background-color:wheat;
border-width: 0rem;
 border-radius: 1rem;
 font-size: 1.8rem;
 font-weight: 700;

}
.about-box{
  margin: 0rem;
    line-height: 4rem !important; 

}
.reach{
  text-align: center;
  font-weight: 600 !important;
  font-size: 2.5rem !important;
}
.abtp{
  word-spacing: 0.2rem !important;
  line-height: 2rem !important; 
  font-weight: 550 !important;
}
.abt{
  font-weight: 900 !important;
  font-size: 2.5rem!important;
color:#936b08

;
}
.view:hover{
background-color:black !important;
color: white !important;
font-weight:bold !important;
}
.upld{
  border-radius: 0.7rem;
  border-color:magenta;
  /* border-width: 0.01px; */
}

.login_bg{
  background-size: cover;
   /* height: 100%;
   width: 100%; */
   background-repeat:   no-repeat;
   background-position:  center;   
  

}
.hero{
  margin-right: 0;
}

.nav1{
  display: flex;
  
}
.invalid{
  margin-bottom: 2rem;
}
.image{
  width: 100%; /* Ensure the container spans the full width */
    max-width: 100%; /* Ensure the image itself doesn't exceed its natural size */
    overflow: hidden;
}
.about{
width: 100%;

}
.from-container {
  margin: 4rem 6rem;
  color: #2a2a2a;
  text-align: center;
}

.from-container form {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: auto;
 
}
.msg{
  margin-top: 6rem  !important;
}
.from-container input {
  height: 3rem;
  padding: 0 1rem;
  margin-bottom: 2rem;
  border-radius: 0.3rem;
  border: 1px solid #2a2a2a;
}

.from-container textarea {
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 0.3rem;
  border: 1px solid #2a2a2a;
}

.from-container button {
  display: inline;
}

@media screen and (max-width: 850px) {
  .from-container {
    margin: 4rem 2rem;
  }
  .from-container form {
    padding-top: 2rem;
    width: 90%;
  }
}
/* slider.css */


.slick-prev:before, .slick-next:before {
  color: black !important; /* Change the arrow icon color to black */
  
}
/* Add this to your CSS file */
.image-container:hover .zoom-image {
  transform: scale(1.5); /* Increase the size by 10% */
}


.nac{
  background-color:#F0F1FF!important;
}
.ttt{
  font-family: Copperplate, Papyrus, fantasy !important;
  font-style: italic ;
 
}
.ttt1{
  opacity: 0.98;
}
.semibold{
font-weight: 300 !important;
}
.car{
  border-radius: 1rem !important;
  border-width: 0.1rem;
  border-color:seagreen;
  height: 35rem !important;
  width: 45rem !important;
  object-fit:fill;
  border-style:solid;
  
}
.oc{
  background-color: white !important;
  width: 100 !important ; 
  color:#936b08 !important;
  margin-top: 4rem !important;
  font-size:xxx-large !important;
  font-weight: 900;
  
cursor:default;
}
.card1{
display: flex !important;
/* width: 100%; */
flex-wrap: wrap;
justify-content: center;
margin: 2rem;

cursor:default;
}
.c1{
  margin: 1rem;
  border-radius: 2rem !important;
  border-width: 0.1rem;  
  border-color: crimson;
  transition: all .3s ease-in-out !important;

  cursor:default;
}
.c1:hover{
  /* transition: filter 300ms ease-in-out, transform 1s; */
    transform: scale(1.2);
   


}
.heading{
  color:slateblue !important;
  
cursor:default;
}
.body1{
  font-weight:600 !important;
  
cursor:default;
}
.we{
  font-weight: 500;
color:#282c34;
cursor:default;
}

.semibold{
  border-radius: 1rem !important;
  border-width: 0.1rem !important;
}

.text-area{
  cursor: pointer !important;
  border-width :0.1rem ;
  border-radius: 1.5rem;
  border-color: maroon;
  padding: 0.5rem;
}
.text-area:focus{
  border: 0 none #FFF !important;
  overflow: hidden !important;
  outline:none !important;

}
.text2{
  border: 0 none #FFF !important;
  overflow: hidden !important;
  outline:none !important;
  border-width :0.1rem ;
  border-radius: 1.5rem;
  border-color: maroon;
  padding: 0.5rem;
}
.form{
  display: flex !important;
 flex-direction: column !important;
background-color:blanchedalmond!important;  
/* #b3f0ff */
border-radius: 1.5rem;
border-color:blue !important ;
border-width: 0.1rem;
}
.input{
  display: flex !important;
  flex-direction: column !important;
  margin: 2rem;
  /* width: 100% !important; */
  font-weight: 700;
 
}
.form{
  width: 100%;
  height: auto !important;

}
.body2{
  height: 15rem !important;
  padding-top: 1rem;
  overflow-y: scroll !important;

}
.title_{
  border-radius: 1rem;
  border-width: 1rem;
  border-color: blue !important;
 
  resize: none;
 padding: 0.3rem;
}

.form-encl1{
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  min-height: 60% !important;
  height: auto !important;
  width: 50%;
 
  /* border-radius: 1rem;
  border-width: 1rem; */
}
html, body, #body2
{
    height: 100%;
    min-height: 100%;
}
.icon{
  size: 2rem;
  color: blue;
}
.s1:hover{
background-color: blue !important;
color: white !important;
font-weight:bold !important;
}
.s1{
  transition: all .3s ease-in-out !important;
border-radius: 1rem !important;
padding: 00.2rem;
color: darkblue !important;
border: 2px solid blueviolet!important;
font-weight: bolder !important;
background-color: chartreuse;
margin: 1rem !important;
}
.s1p{
  display: flex !important;
  flex-direction: column !important;
  align-items:  center !important;
  justify-content: center !important;

}

.close{
  position: absolute;
  right: 0;
  top:0;
  margin-top: 1rem;
  margin-right: 1rem;
  cursor: pointer;
}
.username{
  color:black;
  size: 3rem !important;
}
.pi{
border-width: 0.1rem;
border-color: saddlebrown;
border-radius: 1rem;
height: 25rem;
width: 40rem;
/* ima */
}

.center1{
  width: 65% !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100% !important;
  /* border-width: 2rem !important; */
  margin: auto !important;
 
}
.cardx{
  border-radius: 1rem !important;
  border-width: 0.2rem !important;
  border-color:blueviolet !important;
  box-shadow: 1rem !important;
}
.create{
  /* margin: 3rem !important; */
  border-radius: 1rem !important;
  border-width: 0.1rem !important;
  border-color:blueviolet;
  cursor: pointer;
box-shadow: 1rem;
width: 65% !important;
align-items: center !important;
margin: auto;
margin-top: 2rem ;
margin-bottom: 2rem;
}
.like{
  display: flex;
  align-items: center;
  height: auto;
margin: auto;
cursor: pointer;
}
.cmt{
  margin: 1rem;
font-weight: 600;

}
.create10{
  cursor:default !important;
  border-width :0rem; 
margin: 1rem !important;
}
.wrapper{
  border-width: 0.2rem;
  border-color:blueviolet;
  margin: 0.5rem;
  border-radius: 3rem;
}
.addcmt{
  background-color: blue !important;
  color: ghostwhite !important;
  border-radius: 2rem !important;
}
.cmtcard{
  display: flex !important;
  flex-direction: column;
  margin: 1rem !important;
  margin-right: 1rem !important;
  border-width: 0.1rem !important;
  width: 100%;
  /* border-radius: rem; */
}

.cmtbody{
  margin-left: 1rem;
  margin-top: 0rem;
}
.btn1{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: auto;
}
.controls{
color:white!important;
visibility: visible !important;
opacity:1 !important;
margin: 1rem !important;
 background-color:indigo !important; 
 } 

 .carousel-control-next, .carousel-control-prev{
   opacity: 0.7!important;
   /* margin: 1rem; */
 }
.bass{
color:dimgrey !important;
font-weight: bold;
margin-bottom: 2rem !important;
}
@media screen and (max-width: 768px) {
 
.video{
 border-width: 0.3rem;
 border-color: #000;
 border-radius: 1rem;
 border-style: solid;
 height: 60vh !important;
 width: 95vw !important;
 margin: 0.5rem;
}
.iframeparent{

border-bottom: 0.1rem solid  #000;
border-radius: 0.1rem;
}

}
