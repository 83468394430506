
.product-carousel {
  margin-bottom: 40px;
}

.product-carousel h2 {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center ;
}

.slick-slide img {
  width: 100%;
  height: auto;
}

 .slick-arrow {
 
 
  z-index: 4;
  
  /* background-color: black;  */
} 
.slick-arrow button{
    background-color: black;
}
.slick-prev:before,
.slick-next:before {
  color: blue;
  font-size: 2rem;
}


.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}